import { useEffect } from 'react';
import { setBaseUrl, setVersion } from '@simplywallst/services';
import useUser from '@/hooks/useUser';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { getLocationType } from '@/redux/location/selectors';
import { PUBLIC_RELEASE_VERSION } from '@/constants/env';
import { useFlag } from '@simplywallst/unleash';

export function useAppInit() {
  const user = useUser();
  const {
    loggedIn,
    id,
    billing: { plan, status },
  } = user.data;
  const type = useSelector(getLocationType);

  /** attach user id to analytics */
  useEffect(() => {
    const { analytics } = window;
    if (!analytics.isIdentified() && loggedIn) {
      analytics.dispatch({ type: 'identify', id: id });
      analytics.dispatch({
        type: 'track',
        page: type,
        subject: 'success',
        action: 'identify',
        userId: id,
      });
    }

    if (!loggedIn) {
      analytics.dispatch({ type: 'unidentify' });
    }
  }, [loggedIn, id, type]);

  /** set sentry meta */
  useEffect(() => {
    try {
      if (typeof window !== 'undefined' && !window.TEST_RUNNER && loggedIn) {
        Sentry.setUser({
          id,
        });
        Sentry.setExtras({
          'billing.plan': plan,
          'billing.status': status,
        });
      }
    } catch {
      console.warn('Error setting sentry user');
    }
  }, [loggedIn, id, plan, status]);

  useEffect(() => {
    const registerServiceWorker = async () => {
      try {
        const registration = await navigator.serviceWorker.register(
          '/static/service-worker.js'
        );
        console.log('SW registered: ', registration);
      } catch (e) {
        console.log('SW registration failed: ', e);
      }
    };
    /** Register */
    if (
      NODE_ENV === 'production' &&
      'serviceWorker' in navigator &&
      RUNTIME_ENV === 'browser' &&
      !(typeof window !== 'undefined' && window.TEST_RUNNER) // disable SW in test mode
    ) {
      registerServiceWorker();
    }
  }, []);

  const refreshTabFlag = useFlag('web-refresh-tab-auth');

  // TODO: Remove this once 'web-refresh-tab-auth' is rolled out 100%
  // This effect can be replaced by adding `refetchOnWindowFocus` to the useUser hook once the
  // web-refresh-tab-auth flag is rolled out 100%. We cannot add a conditional to the useUser
  // query options as the options are read and stored when the hook is first used. This will
  // happen before the clientside flag evaluation occurs.
  useEffect(() => {
    /** refresh on focus */
    let focusTime: number = Date.now();
    const onFocus = async () => {
      // When the current page becomes active, refresh the user state to check if we are logged in
      // or not. This is because other tabs may have logged in/out of SWS.
      //
      // No-op on native mobile app, as it has a single auth state for the app.
      if (refreshTabFlag.enabled && RUNTIME_ENV !== 'native') {
        // TODO: use the `useUser` hook `refetchOnWindowFocus=true` option once the
        // `web-refresh-tab-auth` flag is rolled out.
        await user.refetch();
        return;
      }

      const diff = Date.now() - focusTime;
      const sixHours = 21600000;
      if (diff > sixHours) {
        window.location.reload();
      }
    };
    const onBlur = () => {
      focusTime = Date.now();
    };
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [refreshTabFlag.enabled]);

  if (
    RUNTIME_ENV === 'server' &&
    typeof SWS_INTERNAL_API_BASE_URL !== 'undefined'
  ) {
    setBaseUrl(SWS_INTERNAL_API_BASE_URL);
    setVersion(PUBLIC_RELEASE_VERSION);
  }

  /**
   * To surface tests that are missing stubs (and therefore flaky),
   * set DEBUG_FLAKY_TESTS to true, and run Cypress tests.
   */
  const DEBUG_FLAKY_TESTS = false;

  // TODO: Fix flaky tests and remove this configuration.
  if (
    DEBUG_FLAKY_TESTS &&
    typeof window !== 'undefined' &&
    window?.TEST_RUNNER === 'cypress'
  ) {
    setBaseUrl('https://not-stable-dev.simplywall.st/');
  }
}
