import type { ReactNode } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { LocationState } from 'redux-first-router';
import { cn } from '@simplywallst/ui-core/next';
import type { Route } from '@/router/routesGenerator';

interface Props {
  children?: ReactNode;
}

function getMappedRoute(routesMap: LocationState['routesMap'], type: string) {
  if (type in routesMap) {
    /** asserting type because 'Route' is a SWS custom type */
    return routesMap[type] as Route;
  }
}

/**
 * The layout `mobileTopbar` is opt-out by nature.
 */
function resolveLayoutValue(layout: boolean | undefined) {
  if (typeof layout === 'undefined') {
    return true;
  }
  return layout;
}

/**
 *
 * This component exist mainly to isolate renders due to
 * multiple hooks used by `useHasMobileTopbarMessage`
 *
 */
export function MainContainer({ children }: Props) {
  const routeLayout = useSelector<
    { location: LocationState },
    Route['layout'] | undefined
  >((state) => {
    const { location } = state;
    const currentRoute = getMappedRoute(location.routesMap, location.type);
    if (typeof currentRoute !== 'undefined' && currentRoute.layout) {
      return currentRoute.layout;
    }
  }, shallowEqual);

  const hasMobileTopbar = resolveLayoutValue(routeLayout?.mobileTopbar);
  const hasMobileTopbarSubNav = routeLayout?.mobileTopbarSubNav;
  const hasDesktopTopbar = resolveLayoutValue(routeLayout?.desktopTopbar);

  return (
    <div
      className={cn(
        hasMobileTopbarSubNav &&
          'pt-[calc(theme(spacing.x7)*2+env(safe-area-inset-top))]',
        !hasMobileTopbarSubNav &&
          hasMobileTopbar &&
          'pt-[calc(theme(spacing.x8)+env(safe-area-inset-top))]',
        hasDesktopTopbar &&
          'lg:pt-[calc(theme(spacing.x8)+env(safe-area-inset-top))]'
      )}
    >
      {children}
    </div>
  );
}
