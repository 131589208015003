import { getAuthCookie } from '@/utilities/cookies';
import { useDispatch } from 'react-redux';
import { ROUTE_WELCOME } from '@/constants/routes';
import { redirect } from 'redux-first-router';
import { useEffect } from 'react';
import { isAuthenticationNeeded } from '@/router/utils';
import { useLocation } from '@/hooks/useLocation';

/**
 * A component that checks whether the user is authenticated and redirects them if not.
 *
 * @component
 * @example
 * <AuthChecker />
 *
 * @returns {JSX.Element} The rendered React component.
 */
export function AuthChecker() {
  const dispatch = useDispatch();
  const { type, pathname } = useLocation();

  useEffect(() => {
    async function checkCookie() {
      const cookie = await getAuthCookie();
      if (typeof cookie === 'undefined' || cookie?.accessToken === '') {
        dispatch(redirect({ type: ROUTE_WELCOME, query: { r: pathname } }));
      }
    }
    if (isAuthenticationNeeded(type)) {
      checkCookie();
    }
  }, [type, pathname]);

  return <></>;
}
