import { createContext, useState, useEffect, useRef, useCallback } from 'react';
import type { RefObject, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import Media from 'react-media';
import { useTheme } from '@simplywallst/ui-core';
import loadable from '@loadable/component';
import { useUserAgent } from '@simplywallst/hooks/useUserAgent';
import { shallowEqual, useSelector } from 'react-redux';
import type { LocationState } from 'redux-first-router';
import {
  ROUTE_COMPANY,
  ROUTE_LOCALE_COMPANY,
  ROUTE_LOCALE_COMPANY_WITH_EVENTS,
} from '@/constants/routes';

const DesktopTopbar = loadable(() => import('@/components/Nav/DesktopTopbar'));

const MobileTopbarContainer = loadable(
  () => import('@/components/Nav/MobileTopbar/containers/MobileTopbarContainer')
);

export interface ContextType {
  portal?: RefObject<HTMLDivElement | null>;
  wider?: boolean;
  setWider: (wide: boolean) => void;
}

function locationSelector(state: { location: LocationState }) {
  return state.location.type;
}
const LayoutContext = createContext<ContextType>({
  setWider: () => null,
});

export const LayoutProvider = ({
  children,
  layout,
}: {
  children: ReactNode;
  layout?: {
    mobileTopbar?: boolean;
    desktopTopbar?: boolean;
    mobileTopbarSubNav?: boolean;
  };
}) => {
  const theme = useTheme();
  const [portal, setPortal] = useState<RefObject<HTMLDivElement>>();
  const [wide, setWide] = useState(false);
  const portalRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet, isDesktop } = useUserAgent();
  useEffect(() => setPortal(portalRef), []);

  const hasMobileTopbar =
    layout?.mobileTopbar === undefined || layout?.mobileTopbar === true;

  const wideToggler = useCallback((wide: boolean) => {
    setWide(wide);
  }, []);
  const type = useSelector<
    Parameters<typeof locationSelector>[0],
    ReturnType<typeof locationSelector>
  >(locationSelector, shallowEqual);

  useEffect(() => {
    /**
     * This effect resets the wide
     * if the route is other
     * than company overview
     * */
    if (
      wide &&
      type !== ROUTE_COMPANY &&
      type !== ROUTE_LOCALE_COMPANY &&
      type !== ROUTE_LOCALE_COMPANY_WITH_EVENTS
    ) {
      setWide(false);
    }
  }, [type, wide]);

  useEffect(() => {
    try {
      window.dispatchEvent(new Event('resize'));
    } catch {
      /** do nothing */
    }
  }, [wide]);

  return (
    <LayoutContext.Provider
      value={{ portal, wider: wide, setWider: wideToggler }}
    >
      <div className="fixed left-0 top-0 z-nav w-full bg-legacy-noir pt-[calc(0px+env(safe-area-inset-top))]">
        {layout &&
          layout.desktopTopbar === undefined &&
          layout.desktopTopbar !== false && (
            <Media
              query={{ minWidth: theme.lgMinRaw }}
              defaultMatches={isDesktop}
              render={() => <DesktopTopbar />}
            />
          )}
        {hasMobileTopbar && (
          <Media
            query={{ maxWidth: theme.mdMaxRaw }}
            defaultMatches={isMobile || isTablet}
            render={() => (
              <MobileTopbarContainer
                hasSubNav={layout && layout.mobileTopbarSubNav}
              />
            )}
          />
        )}
        <div ref={portalRef} />
      </div>
      {children}
    </LayoutContext.Provider>
  );
};

export const LayoutConsumer = ({ children }: { children: ReactNode }) => {
  return (
    <LayoutContext.Consumer>
      {({ portal }) => {
        if (portal?.current) {
          return createPortal(children, portal.current);
        }
      }}
    </LayoutContext.Consumer>
  );
};

export default LayoutContext;
