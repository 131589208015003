import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

type FloatingNotificationWrapperProps = {
  readonly iconTheme: 'bad' | 'warning';
};

export const FloatingNotificationWrapper = styled(
  'div'
)<FloatingNotificationWrapperProps>`
  position: fixed;
  bottom: ${({ theme }) => theme.x4spacePx}px;
  z-index: 1000;

  ${media.mdDown`
    padding: 0 ${({ theme }) => theme.x1spacePx}px;
    bottom: ${({ theme }) => theme.x4spacePx * 2}px;
  `}

  > div {
    background: ${({ theme }) => theme.color.spaceGray};
    padding: ${({ theme }) => theme.x2spacePx}px;
    ${({ theme }) => theme.typography.xSmall};
    border-radius: 8px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;

    ${media.mdDown`
      padding: ${({ theme }) => theme.x1spacePx}px ${({ theme }) =>
        theme.x2spacePx}px;
    `}

    > svg {
      fill: ${({ theme, iconTheme }) =>
        iconTheme === 'bad' ? theme.color.bad : theme.color.warning};
      margin-right: ${({ theme }) => theme.x1spacePx}px;
    }
  }
`;

export const TextWrapper = styled('div')`
  flex-grow: 1;
`;
