import type { ReactElement } from 'react';
import {
  createContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useBraze } from '@/components/Braze';
import type { InAppMessage, ControlMessage } from '@braze/web-sdk';

interface ModalManagementContextType {
  modalsRef: Readonly<React.MutableRefObject<string[]>>;
  addModal: (id: string) => void;
  removeModal: (id: string) => void;
}

const ModalManagementContext = createContext<
  ModalManagementContextType | undefined
>(undefined);

interface ThemeProviderProps {
  children: ReactElement;
}

export const ModalManagementProvider = ({ children }: ThemeProviderProps) => {
  const modals = useRef<string[]>([]);

  const addModal = useCallback((id: string) => {
    if (id && !modals.current.includes(id)) {
      modals.current = [...modals.current, id];
    }
  }, []);

  const removeModal = useCallback((id: string) => {
    modals.current = modals.current.filter((modal) => modal !== id);
  }, []);

  const brazeSubscriptionId = useRef<string | undefined>(undefined);

  const {
    subscribeToInAppMessage,
    showInAppMessage,
    unsubscribeToInAppMessage,
  } = useBraze();

  useEffect(() => {
    if (brazeSubscriptionId.current) {
      unsubscribeToInAppMessage(brazeSubscriptionId.current);
    }

    brazeSubscriptionId.current = subscribeToInAppMessage(
      (inAppMessage: InAppMessage | ControlMessage) => {
        if (modals.current.length > 0) {
          return;
        }

        showInAppMessage(inAppMessage);

        if (inAppMessage.isControl) {
          return;
        }

        if (inAppMessage.triggerId) {
          addModal(inAppMessage.triggerId);
        }

        inAppMessage.subscribeToDismissedEvent(() => {
          if (inAppMessage.triggerId) {
            removeModal(inAppMessage.triggerId);
          }

          inAppMessage.removeAllSubscriptions();
        });

        inAppMessage.subscribeToClickedEvent(() => {
          if (inAppMessage.triggerId) {
            removeModal(inAppMessage.triggerId);
          }
          inAppMessage.removeAllSubscriptions();
        });
      }
    );

    return () => {
      if (brazeSubscriptionId.current) {
        unsubscribeToInAppMessage(brazeSubscriptionId.current);
      }
    };
  }, [
    addModal,
    removeModal,
    showInAppMessage,
    subscribeToInAppMessage,
    unsubscribeToInAppMessage,
  ]);

  return (
    <ModalManagementContext.Provider
      value={{ modalsRef: modals, addModal, removeModal }}
    >
      {children}
    </ModalManagementContext.Provider>
  );
};

export const useModalManagementContext = (): ModalManagementContextType => {
  const context = useContext(ModalManagementContext);
  if (!context)
    throw new Error(
      'useModalManagement must be used within a ModalManagementProvider'
    );

  return context;
};

export const useModalManagement = (modalId: string) => {
  const [isShowingModal, setIsShowingModal] = useState(false);
  const {
    modalsRef,
    addModal: addModalInManagement,
    removeModal: removeModalInManagement,
  } = useModalManagementContext();

  const addModalIfEmpty = useCallback(() => {
    if (modalsRef.current.length === 0) {
      addModalInManagement(modalId);
      setIsShowingModal(true);
    }
  }, [addModalInManagement, modalId, modalsRef]);

  const removeModal = useCallback(() => {
    removeModalInManagement(modalId);
    setIsShowingModal(false);
  }, [modalId, removeModalInManagement]);

  return { isShowingModal, addModalIfEmpty, removeModal };
};
