import WarningSolid from '@simplywallst/ui-core/icons/WarningSolid';
import { debounce } from 'lodash';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import CloseButton from '../CloseButton';
import { FloatingNotificationWrapper, TextWrapper } from './styled';

type Props = {
  readonly children: ReactNode;
  readonly width: number;
};

export const FloatingNotification = ({ children, width }: Props) => {
  const [hidden, setHidden] = useState(false);
  const [bottomOfPage, setBottomOfPage] = useState(false);

  useEffect(() => {
    const footer = document.querySelector('footer');

    const scrollHandler = debounce(() => {
      if (footer) {
        const { top } = footer.getBoundingClientRect();

        setBottomOfPage(window.innerHeight - top >= 0);
      } else {
        setBottomOfPage(false);
      }
    }, 1000);

    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  });

  if (bottomOfPage || hidden) {
    return null;
  }

  return (
    <FloatingNotificationWrapper
      iconTheme="warning"
      style={{ width: `${width}px` }}
    >
      <div>
        <WarningSolid /> <TextWrapper>{children}</TextWrapper>
        <CloseButton onClick={() => setHidden(true)} />
      </div>
    </FloatingNotificationWrapper>
  );
};
