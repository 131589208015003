import loadable from '@loadable/component';

export const AccountRecoveryContainer = loadable(
  () => import('./AccountRecoveryContainer')
);
export const CompareCompanyContainer = loadable(
  () => import('./CompareCompanyContainer')
);
export const DashboardContainer = loadable(
  () => import('./DashboardContainer')
);

export const OTPLoginContainer = loadable(() => import('@/pages/OTPLogin'), {
  resolveComponent: (component) => component.OTPLogin,
});
export const OffboardingContainer = loadable(
  () => import('./OffboardingContainer'),
  { resolveComponent: (component) => component.Offboarding }
);
export const OopsContainer = loadable(() => import('./OopsContainer'));
export const DeprecatedPortfolioContainer = loadable(
  () => import('./DeprecatedPortfolioContainer')
);
export const DeprecatedPortfolioEditTxnContainer = loadable(
  () => import('./DeprecatedPortfolioEditTxnContainer')
);
export const DeprecatedPortfolioImportContainer = loadable(
  () => import('./DeprecatedPortfolioImportContainer')
);
export const DeprecatedPortfolioReturnsContainer = loadable(
  () => import('./DeprecatedPortfolioReturnsContainer')
);
export const UserContainer = loadable(() => import('./UserContainer'));
