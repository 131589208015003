import type { ReactNode } from 'react';
import { useFlag } from '@/features/unleash';
import loadable from '@loadable/component';
import type { AppUpdateBoundaryRouteFeatureFlags } from './types';

const AppUpdateBoundaryRouteFallback = loadable(
  () => import('./AppUpdateBoundaryRouteFallback'),
  {
    resolveComponent: (c) => c.AppUpdateBoundaryRouteFallback,
  }
);

type Props = {
  children: ReactNode;
  flagName: AppUpdateBoundaryRouteFeatureFlags;
};

export const AppUpdateBoundaryRoute = ({ children, flagName }: Props) => {
  const { enabled } = useFlag({ name: flagName });

  if (RUNTIME_ENV === 'native' && enabled) {
    return <AppUpdateBoundaryRouteFallback />;
  }

  return children;
};
