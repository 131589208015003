import { connect } from 'react-redux';
import Content from '@/components/Content';
import type { Props as ContentProps } from '@/components/Content';

const mapStateToProps = () => {
  return (state): ContentProps => {
    const route =
      state.location &&
      state.location.routesMap &&
      state.location.routesMap[state.location.type];

    return {
      layout: route?.layout ?? {},
    };
  };
};

export default connect(mapStateToProps)(Content);
