import { Box, Text, Modal } from '@simplywallst/ui-core';

import type { Datum } from '../../types';

import { formatterPercentage } from '@/utilities/format';

import { Table, TableRow, TableHeader, TableColumn } from './styled';

const HeaderColumn = ({ children }: { children: string }) => (
  <TableHeader>
    <Box as="span" display="block" paddingX="x1_25" paddingY="x0_5">
      <Text
        as="span"
        typography="xSmallStrong"
        color="inherit"
        textAlign="left"
      >
        {children}
      </Text>
    </Box>
  </TableHeader>
);

const Header = () => {
  return (
    <thead>
      <TableRow>
        <TableHeader />
        <HeaderColumn>Weight</HeaderColumn>
      </TableRow>
    </thead>
  );
};

const Column = ({ children }: { children: string | number }) => (
  <TableColumn>
    <Box as="span" display="block" paddingX="x1_25" paddingY="x0_75">
      <Text as="span" typography="tiny" color="inherit" textAlign="left">
        {children}
      </Text>
    </Box>
  </TableColumn>
);

const Row = ({ label, allocation }: Datum['companies'][number]) => {
  return (
    <TableRow key={`other-${label}`}>
      <Column>{label}</Column>
      <Column>{formatterPercentage(allocation)}</Column>
    </TableRow>
  );
};

interface Props {
  data: Datum;
  onClose: () => void;
}

export const RegionModal = ({ data, onClose }: Props) => {
  return (
    <Modal
      title={`Regional Holdings (${data.label})`}
      fullscreen
      hideFooter
      onClose={onClose}
    >
      <Table>
        <Header />
        <tbody>
          {data.companies.map((n) => (
            <Row key={`other-${n.label}`} {...n} />
          ))}
        </tbody>
      </Table>
    </Modal>
  );
};
