import styled from 'styled-components';

export const Table = styled('table')`
  width: 100%;
  table-layout: auto;
  border-spacing: 0;
  border: 1px solid ${({ theme }) => theme.color.blackRGBA(0.2)};
`;

export const TableRow = styled('tr')`
  text-align: initial;
`;

export const TableHeader = styled('th')`
  text-align: initial;
  vertical-align: middle;
  background-color: ${({ theme }) => theme.color.blackRGBA(0.1)};
`;

export const TableColumn = styled('td')`
  text-align: initial;
  vertical-align: middle;
  border-top: 1px solid ${({ theme }) => theme.color.blackRGBA(0.2)};
`;
